import { useEffect, useState } from "react"

const useIP = () => {
  const [ipData, setIpData] = useState({})
  const [ipAddress, setIpAddress] = useState("")
  const [countryCode, setCountryCode] = useState("ae")

  useEffect(() => {
    fetch("https://freeipapi.com/api/json/")
      .then((res) => res.json())
      .then((data) => {
        setIpData(data)
        setCountryCode(data?.countryCode?.toLowerCase())
        if (data?.ipAddress && data.ipAddress !== ipAddress)
          setIpAddress(data.ipAddress)
      })
      .catch((err) => {
        console.log("🚀 ~ useIP:", err)
      })
  }, [])

  return {
    ...ipData,
    ipAddress,
    countryCode,
  }
}

export default useIP
